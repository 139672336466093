import React from 'react'
import './Reasons.css'
import {reasons} from './reasonsData'

const allReasons = reasons.map( reason => (
    <div className='reasons' key={reason.id}>
        <h1>
            {reason.number}
            <i class={reason.icon}> </i>
        </h1>
        <h2>{reason.header}</h2>
        <p>{reason.content}</p>
    </div>
))

function Reasons() {
    return (
        <div className='reasons-container'>
            <div className='reasons-container-inner'>
                <h3 className='reasons-header'>Why Choose Us</h3>
                <div className='all-reasons-container'>
                    {allReasons}
                </div>
            </div>
        </div>
    )
}

export default Reasons