import React from 'react'
import './Contact.css'
import ContactForm from './ContactFrom'

function Contact() {

    return (
        <div className='contact-us-container'>
            <div className='contact-form'>
                <h3>Contact Us</h3>
                <ContactForm/>
            </div>
        </div>
    )
}

export default Contact