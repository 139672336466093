export const testimonialsData = [
    {
        id: 1,
        name: 'Ken H.',
        city: 'San Francisco, CA',
        content: 'I had come to this body shop multiple times already. Service here is great and the workers were quick with their work. Friendly environment always ready to lend a helping hand. They offered all types of service and maintanance, oil change, and window repair. This is my go to spot whenever I have a problem on my car. Will always come here!'
    },
    {
        id: 2,
        name: 'Lena L.',
        city: 'San Francisco, CA',
        content: 'Someone had done a hit and run on my parked Corolla a block away from my house! I went to a few shops to get an estimate, and I settled with this shop. The price was good and the owner seemed like he was an honest guy. He fixed my car within a few days and I could not be happier! Definitely would recommend this shop if you need any auto services done!'
    },
    {
        id: 3,
        name: 'Lan M.',
        city: 'Danville, CA',
        content: 'Amazing service, saved my mirror, in other body shops it cost me a thousand dollars to replace the mirror assembly.'
    },
    {
        id: 4,
        name: 'Jimmy H.',
        city: 'Oakland, CA',
        content: 'I came in the shop with a squeaky brake on beginning of August. Now is mid September and my brake is still running smoothly. Thank you for your service. I trust you guys with my life because you made my car run smoothly. I will definitely come back to your shop for other services to maintain a good car.'
    },
    {
        id: 5,
        name: 'Frank L.',
        city: 'San Francisco, CA',
        content: 'When I got in an accident, I contacted the shop owner, and he had a tow truck come and tow my car over. My car was in a pretty bad accident. It had frame damage. Needless to say, they got my car running straight again and looking like it was before the accident! Will definitely come back in the future!'
    }
]