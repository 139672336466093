import React from 'react'
import Carousel from '../carousel/Carousel'
import Service from './Service'
import './Service.css'

function ServiceSection() {
    return (
        <div className='service-section'>
            <Service/>
            <Carousel/>
        </div>
    )
}

export default ServiceSection