import React from "react";
import "./Service.css";
import "./ServiceCard.css";
import ServiceCard from "./ServiceCard";
import { serviceData } from "./serviceData";

const allServices = serviceData.map((service) => (
  <ServiceCard key={service.id} service={service} />
));

function Service() {
  return (
    <div className="service-container" id="services">
      <div className="service-container-inner">
        <h2>Services We Offer</h2>
        <p>
          Our service facility is independently owned and operated providing
          full service repairs.
        </p>
        <div className="services">{allServices}</div>
      </div>
    </div>
  );
}

export default Service;
