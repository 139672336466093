import './App.css';
import React from 'react'
import {Route, Switch} from 'react-router-dom'
import Header from './components/header/Header'
import Home from './components/home/Home'
import Services from './components/services/ServiceSection'
import Testimonials from './components/testimonials/Testimonials'
import Contact from './components/contact/Contact'
import Footer from './components/footer/Footer'

function App() {

  return (
    <div className="App">
      <Header/>
      <Switch>
            <Route exact path='/' exact component={Home}/> 
            <Route path='/services' component={Services}/>  
            <Route path='/reviews' component={Testimonials}/> 
            <Route path='/contact' component={Contact}/> 
        </Switch> 
      <Footer/>
    </div>
  );
}

export default App;
