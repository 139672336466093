import React, { useState, useEffect } from "react";
import useEventListener from "./headerHook";
import { NavLink } from "react-router-dom";
import "./Header.css";
import Logo from "../../logo0.png";
// import useReactSimpleMatchMedia from 'react-simple-matchmedia'

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [navBar, setNavBar] = useState(false);

  function toggleMenu() {
    setMenuOpen(!menuOpen);
  }

  function scrollToTop() {
    window.scrollTo(0, 0);
  }

  function resizeSideNav(e) {
    // console.log(window.innerWidth > 801 , menuOpen)
    if (window.innerWidth > 801 && menuOpen === true) {
      setMenuOpen(false);
    }
  }

  useEffect(() => {}, []);

  const changeBackground = () => {
    // console.log(window.scrollY)
    // If the scroll Y is more than height of navbar
    if (window.scrollY >= 80) {
      setNavBar(true);
    } else {
      setNavBar(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  useEventListener("resize", resizeSideNav);

  return (
    <>
      <header className={navBar ? "header active" : "header"}>
        <NavLink to="/" onClick={scrollToTop}>
          {/* <img className='logo' src={Logo}></img> */}
          <h1 className="logo-title">H&L Auto Body</h1>
        </NavLink>

        <div
          className={!menuOpen ? "hamburger" : "hamburger active"}
          onClick={toggleMenu}
        >
          <div className="hamburger-btn"></div>
        </div>

        <ul className="nav">
          <li>
            <NavLink
              activeClassName="selected"
              exact
              to="/"
              onClick={scrollToTop}
            >
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="selected"
              to="/services"
              onClick={scrollToTop}
            >
              Services
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="selected"
              to="/reviews"
              onClick={scrollToTop}
            >
              Reviews
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="selected"
              to="/contact"
              onClick={scrollToTop}
            >
              Contact
            </NavLink>
          </li>
          <li>
            <a
              target="_blank"
              href="https://www.yelp.com/biz/h-and-l-auto-body-oakland"
            >
              {/* <i style={{color: '#af0606'}} class="fab fa-yelp"></i> */}
              <i class="fab fa-yelp"></i>
            </a>
          </li>
        </ul>
      </header>

      <ul className={!menuOpen ? "sideNav" : "sideNav active"}>
        <li>
          <NavLink onClick={toggleMenu} to="/">
            Home
          </NavLink>
        </li>
        <li>
          <NavLink onClick={toggleMenu} to="/services">
            Services
          </NavLink>
        </li>
        <li>
          <NavLink onClick={toggleMenu} to="/reviews">
            Reviews
          </NavLink>
        </li>
        <li>
          <NavLink onClick={toggleMenu} to="/contact">
            Contact
          </NavLink>
        </li>
        <li>
          <a
            target="_blank"
            href="https://www.yelp.com/biz/h-and-l-auto-body-oakland"
          >
            <i class="fab fa-yelp"></i>
          </a>
        </li>
      </ul>
    </>
  );
}

export default Header;
