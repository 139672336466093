export const serviceData = [
    {   
        id: '1',
        header: 'Major Collision',
        content: 'You can put your trust in us to fix and repair your car back to its pre-collision condition! We work with all insruance companies.'
    },
    {
        id: '2',
        header: 'Dents & Dings',
        content: 'Dents and dings can be really annoying to look at, come bring your car in and we will fix it for you making your car look brand new again.'
    },
    {
        id: '3',
        header: 'Frame Damage',
        content: 'We specialize in fixing frame damages and will get your car running straight again if there is frame damage.'
    },
    {
        id: '4',
        header: 'Painting',
        content: 'If you want to touch up or do any paint work on your car, do not hesistate to come and ask for a quote!'
    },
    {
        id: '5',
        header: 'Oil Change',
        content: `It is important to do routine oil changes on your car, otherwise it won't be running as good as it should. We can get you in and out with a quick oil change today!`
    },
    {
        id: '6',
        header: 'Brakes',
        content: 'Are your brakes making noises? Are they feeling a bit mushy? Come by and we will see what the issue is and resolve it right away.'
    }
]