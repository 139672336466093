import React from 'react'
import {Link} from 'react-router-dom'
import './ServiceCard.css'
import './Service.css'

function ServiceCard(props) {
    const {service} = props
    return (
        <div className='service-card'>
            <div className={`card-image-${service.id}`}></div>
            <div className='card-content'>
                <h3>{service.header}</h3>
                <p>{service.content}</p>
                <div className='contact-container'>
                    <Link to='/contact'> Contact Us</Link>
                </div>
            </div>
        </div>
    )
}

export default ServiceCard