import img1 from './images/subaru.jpg'
import img2 from './images/subaru_2.jpg'
import img3 from './images/subaru_3.jpg'
import img4 from './images/fixed_subie_1.jpg'
import img5 from './images/fixed_subie_2.jpg'
import img6 from './images/fixed_subie_3.JPG'
import img7 from './images/fixed_subie_4.jpg'
import img8 from './images/m3_3.jpg'
import img9 from './images/m3_4.jpg'
import img10 from './images/merc1.JPG'
import img11 from './images/merc2.JPG'
import img12 from './images/gtr1.JPG'
import img13 from './images/gtr2.jpg'

export const imagesData = [
    {
        image: img1
    },
    {
        image: img2
    },
    {
        image: img3
    },
    {
        image: img4
    },
    {
        image: img5
    },
    {
        image: img6
    },
    {
        image: img7
    },
    {
        image: img8
    },
    {
        image: img9
    },
    {
        image: img10
    },
    {
        image: img11
    },
    {
        image: img12
    },
    {
        image: img13
    }
]