import React from 'react'
import './Testimonials.css'
import {testimonialsData} from './testimonialsData'
import starImg from './stars.png'

const allTestimonials = testimonialsData.map(test => (
    <div className='test-container' key={test.id}>
        <i className="fas fa-quote-left"></i>
        <p className='comment'>{test.content}</p>
        <img className='stars' src={starImg}></img>
        <p className='name'>{test.name}</p>
        <p className='city'>{test.city}</p>
    </div>
))

function Testimonials() {
        
    return (
        <div className='testimonials-container'>
            <div className='testimonials-container-inner'>
                <h2 className='test-header'>Reviews</h2>
                {allTestimonials}
            </div>
        </div>
    )
}

export default Testimonials