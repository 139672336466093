export const storeHoursData = [
    {
        id: 1,
        day: 'Monday',
        hours: '9AM - 5PM'
    },
    {
        id: 2,
        day: 'Tuesday',
        hours: '9AM - 5PM'
    },
    {
        id: 3,
        day: 'Wednesday',
        hours: '9AM - 5PM'
    },
    {
        id: 4,
        day: 'Thursday',
        hours: '9AM - 5PM'
    },
    {
        id: 5,
        day: 'Friday',
        hours: '9AM - 5PM'
    },
    {
        id: 6,
        day: 'Saturday',
        hours: 'Closed'
    },
    {
        id: 7,
        day: 'Sunday',
        hours: 'Closed'
    },
]