export const reasons = [
    {
        id: 1,
        icon: "fas fa-tools",
        number: '01',
        header: 'Modern Equipment',
        content: 'Our car repair business has the latest equipment to get the job done properly.'
    },
    {
        id: 2,
        icon: 'fas fa-users',
        number: '02',
        header: 'Talented Workers',
        content: 'Our team has a variety of professional specialists.'
    },
    {
        id: 3,
        icon: "fas fa-hand-holding-usd",
        number: '03',
        header: 'Best Price',
        content: `You won't find cheaper prices anywhere else!`
    },
    {
        id: 4,
        icon: "fas fa-toolbox",
        number: '04',
        header: 'Quick Support',
        content: 'Contact us beforehand so we can diagnose your car right away.'
    },
]