import React, {useState} from 'react'
import './ContactForm.css'
import emailjs from 'emailjs-com'


function ContactForm() {

    const [active, setActive] = useState(false)

    function toggleActive() {
        setActive(!active)
    }

    function sendEmail(e) {
    e.preventDefault();
    emailjs.sendForm('Gmail', 'template_9nc8wgs', e.target, 'user_FSWwId9w8gNQJml5CKO0B')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
        toggleActive();
        e.target.reset()
    }

    return (
        <>
        <form onSubmit={sendEmail}> 
            <input 
                type='text'
                placeholder='Your Name'
                name='name'
                required
            />
            <input 
                type='text'
                placeholder='Your Email'
                name='email'
                required
            />
            <input 
                type='text'
                placeholder='Your Phone Number'
                name='number'
                required
            />
            <input 
                type='text'
                placeholder='Your Subject'
                name='subject'
                required
            />
            <textarea 
                name='message'
                placeholder='Your Message'
                required
            >
            </textarea>
            
            <button>Submit</button>
        </form>
        <div className={active ? 'success-message active' : 'success-message'}>
            <div className='message'>
                <p>
                    <span>Success! </span>
                    Your message has been sent successfully.
                </p>
            </div>
            <i 
                class="far fa-times-circle"
                onClick={toggleActive}
            ></i>
        </div>
        </>
    )
}

export default ContactForm