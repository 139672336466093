import React from 'react'
import './About.css'

function About({ reference }) {

    return (
        <div className='about-container' id='about' ref={reference}>
            <div className='about-container-inner'>
                <h2>About Us</h2>
                <p>H&L Auto Body is a highly reputable shop here in Oakland, California. We handle all collision related work and we are a top quality one stop shop for auto body repair services. We specialize in body repair and painting, and we work with most insurance companies. Let us be the shop to help you out and return your car back to its original condition!</p>
                <div className='img-container'></div>
            </div>
        </div>
    )
}

export default About