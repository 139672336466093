import React from 'react'
import {Link} from 'react-router-dom'
import MapSection from './Map'
import Map2 from './Map2'

const location = {
    address: '1221 12th Ave, Oakland, CA 94606',
    lat: 37.79055852178487,
    lng: -122.248634259245
}

function Location() {
    return (
        <div className='location-container'>
            <h3>Location</h3>

            {/* <MapSection location={location} zoomLevel={17}/> */}

            <Map2/>

            <div className='address'>
                <p>1221 12th Avenue</p>
                <p>Oakland, CA 94606</p>
                <p>Phone: (510) 763-0138</p>
                <p>Email: H2LAUTOBODY@YAHOO.COM</p>
            </div>
            <div className='contact-us'>
                <p>Have any questions? </p>
                <Link to='/contact'> Contact Us</Link>
            </div>
        </div>
    )
}

export default Location

