import React from "react";
import { Link } from "react-router-dom";
import "./Hero.css";

function Hero(props) {
  console.log(props);
  return (
    <>
      <div className="hero-container">
        <div className="darken-hero">
          <div className="hero-container-inner">
            <div className="heading">
              <h1>H&L Auto Body</h1>
              <h2>Commitment to quality</h2>
            </div>
            <div className="sub-section">
              <p>
                <i class="fas fa-phone-alt"></i>( 510 ) 763-1368
              </p>
              <Link to="/contact">
                <i class="far fa-envelope"></i>
                Contact Us
              </Link>
            </div>

            <button className="learn-more-btn" onClick={props.click}>
              <span>Learn More</span>
              <i class="fas fa-angle-double-down"></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Hero;
