import React from 'react'
import './Map2.css'

function Map2() {
    return (
        <div className="map2">
            <iframe 
                id="iframemap" 
                src="https://maps.google.com/maps?q=1221+12th+Avenue+Oakland%2C+CA+94606&amp;ie=UTF8&amp;iwloc=&amp;output=embed" width="100%" height="500" frameborder="0" scrolling="no">
            </iframe>
            
            <div 
                style={{ color: '#333',
                        fontSize: '14px', 
                        fontFamily: 'Arial, Helvetica, sans-serif',
                        textAlign: 'right',
                        padding: '10px'
                }}
            > 
            <a 
                style= {{ color: '#333',
                        textDecoration: 'underline',
                        fontSize: '14px',
                        fontFamily: 'Arial, Helvetica, sans-serif',
                        textAlign: 'right' 
                }}
                href="http://earth3dmap.com/?from=embed" 
                target="_blank" 
            >
            </a>
            </div>

        </div>
    )
}

export default Map2