import React, {useState} from 'react'
import './Carousel.css'
import {imagesData} from './imagesData'
import {FaArrowAltCircleRight, FaArrowAltCircleLeft} from 'react-icons/fa' 

function Carousel() {
    const [current, setCurrent] = useState(0)
    const length = imagesData.length

    const nextSlide = () => {
        setCurrent( current === length - 1 ? 0 : current + 1 )
    }

    const prevSlide = () => {
        setCurrent( current === 0 ? length - 1 : current - 1)
    }

    if(!Array.isArray(imagesData) || imagesData.length <= 0) {
        return null;
    }

    // console.log(current)

    return (
        <div className='carousel-container'>

            <h2>Some Of Our Previous Work</h2>

            <div className='slider'>
                <FaArrowAltCircleLeft className='left-arrow' onClick={prevSlide} />
                <FaArrowAltCircleRight className='right-arrow' onClick={nextSlide} />

                {imagesData.map( (slide, index) => {
                    return (
                        <div className={index === current ? 'slide active' : 'slide'} key={index}>
                            {index === current && (
                                <img className='image' src={slide.image} alt='car-image'/>
                            )}
                        </div>
                    )
                })}

            </div>

        </div>
    )
}

export default Carousel