import React from 'react'
import './Footer.css'
import Location from '../location/Location'
import StoreHours from '../hours/StoreHours'


function Footer() {
    return (
        <footer>
            <div className='footer'>
                <Location/>
                <StoreHours/>
            </div>
        </footer>
    )
}

export default Footer