import React, {useRef} from 'react'
import Hero from '../hero/Hero'
import About from '../about/About'
import Reasons from '../reasons/Reasons'

function Home() {

    const scrollToDiv = (ref) => window.scrollTo(0, ref.current.offsetTop)
    const el1 = useRef()
    const el2 = useRef()

    return (
        <div>
            <Hero reference={el1} click={() => scrollToDiv(el2)}/>
            <About reference={el2}/>
            <Reasons/>
        </div>
    )
}

export default Home