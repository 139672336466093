import React from 'react'
import './StoreHours.css'
import {storeHoursData} from './storeHoursData'

const storeHours = storeHoursData.map(day => (
    <div key={day.id} className='day-container'>
        <p>{day.day}</p>
        <p 
        style={{
            color: day.hours === 'Closed' && 'pink',
            fontStyle: day.hours === 'Closed' && 'italic'
        }}>
            {day.hours}
        </p>
    </div>
))

function StoreHours() {
    return (
        <div className='store-hours-container'>
            <h3>Store Hours</h3>
            {storeHours}
        </div>
    )
}

export default StoreHours